import Table from './Table'

function getData (componentName, intl) {
  return ComponentsData[componentName](intl)
}

const ComponentsData = {
  Table: Table
}

export default getData
