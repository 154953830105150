import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'gatsby-plugin-intl'
import { Div, Container } from 'atomize-jnh'
import Table from './Table'
import getData from './data'

const PageBody = ({ intl }) => {
  return (
    <Div p={{ b: '5rem', t: '1rem' }} style={{ width: '100%', background: '#e9f3fe' }}>
      <Container>
        <Table {...getData('Table', intl)} />
      </Container>
    </Div>
  )
}

export default injectIntl(PageBody)

PageBody.propTypes = {
  intl: PropTypes.object
}
