import React from 'react'
import { Div, Text, Icon } from 'atomize-jnh'
import PropTypes from 'prop-types'

const DirectoryInfo = ({ index }) => {
  return (
    <Div d="flex">
      <Icon name="Home" color="#787878" size="20px" />
      <Text m={{ l: '5px' }} textColor="#787878">
        {'>'}
      </Text>
      <Text m={{ l: '5px' }} textColor="#787878">
        {index}
      </Text>
    </Div>
  )
}

export default DirectoryInfo

DirectoryInfo.propTypes = {
  index: PropTypes.string
}
