const Table = intl => {
  return {
    langCode: intl.formatMessage({ id: 'langCode' }),
    pageData: {
      index: intl.formatMessage({ id: 'pricesPage.index' }),
      title: intl.formatMessage({ id: 'pricesPage.title' })
    },
    tableData: {
      tableTitle: intl.formatMessage({ id: 'priceTable.tableTitle' }),
      actionButton: {
        desktop: intl.formatMessage({ id: 'priceTable.actionButton.desktop' }),
        mobile: intl.formatMessage({ id: 'priceTable.actionButton.mobile' })
      },
      header: {
        currency: intl.formatMessage({ id: 'priceTable.header.currency' }),
        price: intl.formatMessage({ id: 'priceTable.header.price' }),
        change: intl.formatMessage({ id: 'priceTable.header.change' }),
        operate: intl.formatMessage({ id: 'priceTable.header.operate' }),
        global: intl.formatMessage({ id: 'priceTable.header.global' })
      }
    }
  }
}

export default Table
