import React from 'react'
import PropTypes from 'prop-types'
import { Div, Text } from 'atomize-jnh'
import renderDirectoryInfo from './DirectoryInfo'
import Table from '../../Table/prices'

const CompleteTable = ({ langCode, tableData, pageData }) => {
  return (
    <Div style={{ background: '#e9f3fe' }}>
      {renderDirectoryInfo({ index: pageData.index })}
      <Text textSize="display1" textWeight="700">
        {pageData.title}
      </Text>

      <Table tableType="full-table" langCode={langCode} tableData={tableData} />
    </Div>
  )
}

export default CompleteTable

CompleteTable.propTypes = {
  langCode: PropTypes.string,
  tableData: PropTypes.object,
  pageData: PropTypes.object
}
