import React from 'react'
import SEO from '../components/Seo'
import PropTypes from 'prop-types'
import Layout from '../layouts/'
import { injectIntl } from 'gatsby-plugin-intl'
import PageBody from '../components/Pages/Prices'

const IndexPage = ({ intl, location }) => {
  return (
    <Layout>
      <SEO
        defaultImage="https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&w=1952&q=60&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D"
        location={location}
        type="prices-view"
        title={intl.formatMessage({ id: 'titles.prices' })}
        description={intl.formatMessage({ id: 'metaDescriptions.prices' })}
        keywords={'Criptomonedas, Criptomonedas Chile, Comprar Criptomonedas'}
      />
      <PageBody />
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object,
  intl: PropTypes.object
}

export default injectIntl(IndexPage)
